import styled from "@emotion/styled";
import { toRadians } from "utils/math";
import { TOP_BAR } from "constants/z-index";
import Colors from "styles/colors";
export const triangleHeightFactor = Math.sin(toRadians(8));
export const StyledBaseLayoutSegmentComponent = styled.div`
  &.ease-in {
    opacity: 0;

    &.ease-in-animation {
      animation: fade-in 0.5s ease-in forwards;

      .segment-wrapper {
        animation: slide-in 0.8s ease-in;
      }
    }

    .segment-wrapper {
      transform: none;
    }
  }

	.segment-wrapper {
		transform: none;

		&.card {
			margin: 0 16px;
			border-top-left-radius: 40px;
			border-top-right-radius: 40px;
			background-color: ${({ cardBackgroundColor })=>Colors[cardBackgroundColor]};
		}
	}

  &.sticky {
    position: sticky;
    top: ${({ stickyTopOffset })=>stickyTopOffset || 0};
    z-index: ${TOP_BAR};
  }

  .triangle {
    position: absolute;
    z-index: 2;
    pointer-events: none;

    &.top {
      width: 0;
      height: 0;
      border-right: 100vw solid transparent;
      // the 0.05 addition solves a number rounding bug resulting in a 1px white line displayed  
      margin-top: -${100 * triangleHeightFactor - 0.05}vw;
    }

    &.bottom {
      width: 0;
      height: 0;
      border-left: 100vw solid transparent;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateY(40px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;
